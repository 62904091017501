import React, { useContext } from "react";
import dynamic from "next/dynamic";
import { StoreContext } from "../context/context";
import { useTranslation } from "../context/TranslationContext";
const FavouritesList = dynamic(
  () => import("../components/Header/FavouritesList"),
  {
    ssr: false,
  }
);
const FavouritesRight = () => {
  const { favouritesRightTitle } = useTranslation();
  const { setFavouritesIsOpen, favouritesIsOpen } = useContext(StoreContext);
  function Close() {
    setFavouritesIsOpen(0);
  }
  return (
    <div
      className={
        "fixed  flex-col flex right-0 top-0 bg-white shadow-lg w-72 rounded-sm h-screen px-3 py-5 overflow-y-auto z-50 transition-all transform-gpu " +
        (favouritesIsOpen ? "translate-x-0 " : "translate-x-full ")
      }
    >
      <div className="flex justify-between items-center mb-3 ">
        <strong className="text-lg inline-block">{favouritesRightTitle}</strong>
        <button
          onClick={(e) => {
            Close();
          }}
        >
          x
        </button>
      </div>
      {favouritesIsOpen ? <FavouritesList /> : null}
    </div>
  );
};

export default FavouritesRight;
