import React, { useContext, useEffect } from "react";
import dynamic from "next/dynamic";
import { ToastContainer } from "react-toastify";
import { StoreContext } from "../context/context";
import Backdrop from "./Backdrop";
const Menubar = dynamic(() => import("./Menubar"), {
  ssr: false,
});
import FavouritesRight from "./FavouritesRight";
import isMobile from "is-mobile";

const WhatsappDesktop = dynamic(() => import("./WhatsappDesktop"), {
  ssr: false,
});
const Footer = dynamic(() => import("../static/Footer"));

const Layout = ({ ssr, children }) => {
  const isPhone = isMobile();
  const { setPage, setCurrentRoutingTypeId, currentRoutingTypeId } =
    useContext(StoreContext);

  useEffect(() => {
    setCurrentRoutingTypeId(ssr.RoutingTypeId);
    setPage(ssr.result);
  }, [ssr]);

  return (
    <>
      {children}
      <Backdrop />
      <ToastContainer />
      <FavouritesRight />
      <Menubar ssr={ssr.result} RoutingTypeId={ssr.RoutingTypeId} />
      {!isPhone ? <WhatsappDesktop /> : null}
      {ssr.result.footer != "0" && <Footer ssr={ssr} />}
    </>
  );
};

export default Layout;
