import React, { useContext } from "react";
import { StoreContext } from "../context/context";

const Backdrop = () => {
  const { backdrop, setBackdrop } = useContext(StoreContext);
  if (backdrop)
    return (
      <div
        className="fixed z-50 bg-black bg-opacity-70 w-screen  inset-0 h-screen "
        onClick={() => {
          setBackdrop(false);
        }}
      ></div>
    );
};

export default Backdrop;
